<template>
  <div class="col-lg-3 col-md-4 col-sm-6 col-6 art-item-wrapper">
    <div class="art-item prod-list">
      <div class="wpr-pr-general">
        <div class="lichidare-de-stoc">
          <img src="@/assets/product/Sale.png" alt="" />
        </div>
      </div>

      <div class="img-prod-art-wrapper">
        <!-- link categorie -->
        <router-link :to="{ name: 'ProductList', params: { slug: categ.slug } }">
          <!-- poza categorie -->
          <img :src="categ.preview_image" />
        </router-link>
      </div>
      <div class="read-more">
        <!-- link categorie -->
        <router-link
          :to="{ name: 'ProductList', params: { slug: categ.slug } }"
          class="categ-title"
          >{{ categ.name }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["categ"],
};
</script>

<style scoped>
.read-more {
  background-color: #27272a;
  text-align: left;
  /* padding-left: 15px; */
  text-overflow: ellipsis;
  color: #fff;
  /* line-height: 30px; */
  text-transform: uppercase;
  width: 100%;
  display: table;
  font-family: Raleway Semibold;
  /* overflow: hidden; */
}

.read-more a {
  background-color: #ffff;
  color: #000;
  font-family: Montserrat SemiBold;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 20px 0;
  height: 90px;
  vertical-align: top;
  text-overflow: ellipsis;
  width: 100%;
  display: table-cell;
  text-decoration: none;
}

.img-prod-art-wrapper {
  max-height: 250px;
  overflow: hidden;
}

.img-prod-art-wrapper img {
  width: 100%;
}

.art-categ .art-item:hover .read-more a,
.grile_view .art-item:hover .read-more a {
  color: #97040c;
}

@media (max-width: 768px) {
  .art-item-wrapper {
    margin-bottom: 15px;
    position: relative;
    padding: 0 15px;
  }
}

@media (max-width: 568px) {
  .art-item-wrapper {
    width: 100%;
  }

  .img-prod-art-wrapper {
    max-height: 100%;
    position: relative;
    border-radius: 6px;
    border: solid 1px #f6f6f6;
  }

  .img-prod-art-wrapper img {
    max-height: none;
  }

  .read-more , .read-more a{
    background-color: #f5f4f2;
  }

  .list-categ-wrraper a.categ-title {
      font-size: 12px;
      font-family: Montserrat Bold;
  }
}
</style>
