<template>
  <div class="default-bg promotion-page list-products-page">
    <page-header :item="header_image" />
    <slogan-livrare :slogan="slogan" />
    <transition name="fade">
      <promotions-navbar-filter
        v-if="windowWidth > 768 && filterCategs.length"
        :categories="filterCategs"
        :class="{ sticky: scrollNavbar }"
        @category-id="setPromoCategory"
      />
    </transition>
    <div id="mm-0" class="mm-page mm-slideout">
      <div class="container main-area">
        <div class="row first-section produse">
          <div id="promotii">
            <div class="content-box">
              <promotions-navbar-filter-mobile
                v-show="windowWidth < 768 && navbarFilterMobile"
                :categories="filterCategs"
                @closeMobileFilter="closeFilter"
                @category-id="setPromoCategory"
              />
              <div v-show="windowWidth < 576" class="promotions-title container">
                  Promotii
              </div>
              <div class="categ_short_desc container">
                  <h1 class="categ-title">Promotii seminee si accesorii</h1>
                  <p>
                      Bucura-te de preturi reduse la accesorii, bio combustibil si o
                      varietate de modele de seminee, valabile in limita stocului
                      disponibil. Platesti mai putin pentru aceeasi calitate, rezistenta si
                      siguranta!
                  </p>
                  <button
                    v-if="windowWidth < 768"
                    class="btn filter-button rounded-pill"
                    :class="{'filter-mobile-sticky': scrollNavbar}"
                    @click="showMobileFilter"
                  >
                    <span v-if="this.categoryId" class="no-of-filters">
                      1
                    </span>
                    <span>Filtreaza</span>
                  </button>
              </div>
              <div
                v-if="!productsLoaded"
                class="container"
              >
                  <ul class="list-unstyled flex-wrap row pt-4">
                      <SkeletonProductCard v-for="n in 8" :key="n" />
                  </ul>
              </div>
              <promotii
                v-for="(category, index) in activeCategs"
                :key="index"
                :category="category"
                :products="products"
                :lastPage="lastPage"
                :class="(index % 2) ? '' : 'white-background'"
                @intersect="intersected"
              />
              <div
                    v-if="!lastPage"
                    class="container"
                    :class="(index % 2) ? '' : 'white-background'"
                >
                    <ul class="list-unstyled flex-wrap row pt-4">
                        <SkeletonProductCard v-for="n in 4" :key="n" />
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Promotii from "@/components/reduceri/Promotii.vue";
import PromotionsNavbarFilter from "@/components/filter/PromotionsNavbarFilter.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";
import SloganLivrare from "@/components/product_list/SloganLivrare.vue";
import PromotionsNavbarFilterMobile from "@/components/filter/PromotionsNavbarFilterMobile.vue";
import SkeletonProductCard from "@/components/blocks/skeleton/shop/SkeletonProductCard.vue";

export default {
  name: "Reduceri",
  components: {
    Promotii,
    PromotionsNavbarFilter,
    PromotionsNavbarFilterMobile,
    "page-header": PageHeader,
    SloganLivrare,
    SkeletonProductCard,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      scrollNavbar: false,
      navbarFilterMobile: false,
      filterCategs: [],
      activeCategs: [],
      products: [],
      start: 0,
      limit: 20,
      lastPage: false,
      productsLoaded: false,
      categoryId: null,
    };
  },
  computed: {
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
    slogan() {
      return "Livrare in toata Romania";
    },
  },
  methods: {
    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 260) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },
    showMobileFilter() {
      this.navbarFilterMobile = true;
    },
    closeFilter() {
      this.navbarFilterMobile = false;
      this.$store.dispatch("filter/actionImgFilter", true);
    },
    getPromotionProducts() {
      this.$store.dispatch("shop/getPromotions", {limit: this.limit, start: this.start, category_id: this.categoryId}). then((response) => {
          this.start = response.next_start;
          this.lastPage = response.last_page;
          const responseArr = response.products.reduce((acc, item) => {
            const categoryId = item.category.id;
            if (!acc[categoryId]) {
              acc[categoryId] = {
                categories: item?.categories ? item.categories : undefined,
                products: item.products,
              };
            } else {
              acc[categoryId].products.push(item.products);
            }

            // Assign active promo categories (that have products)
            if (!this.activeCategs.some((existingCategory) => existingCategory.id ===  item.category.id) && item.products) {
              this.activeCategs.push({id: item.category.id, title: item.category.name});
            }

            return acc;
          }, {});
          var resArr = this.mergeArraysRecursive(this.products, responseArr);
          this.products = resArr;
          this.productsLoaded = true;
      });
    },
    setPromoCategory(category_id) {
      if(category_id === null) {
        // Removing the "All" button
        this.filterCategs = this.filterCategs.filter(function(item) {
          return item.id !== null;
        });
      } else {
        var hasAllBtn = this.filterCategs.some(function(item) {
          return item.id === null;
        });
        // Adding the "All" button at the beginning if not found
        if(!hasAllBtn) {
          this.filterCategs.unshift({id: null, title: 'Toate'});
        }
      }

      this.products = [];
      this.start = 0;
      this.categoryId = category_id;
      this.productsLoaded = false;
      var filteredArray = this.filterCategs.filter(function(item) {
        return item.id === category_id;
      });
      this.activeCategs = filteredArray;

      this.getPromotionProducts();

    },
    intersected() {
      if((!this.lastPage && this.activeCategs.length <= this.filterCategs.length) && this.start > 0) {
        this.getPromotionProducts();
      }
    },
    mergeArraysRecursive(target, source) {
      const merged = { ...target };

      const sourceKeys = Object.keys(source);
      for (const key of sourceKeys) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          if (Array.isArray(source[key]) && (target[key] !== undefined) && Array.isArray(target[key])) {
            // Merge arrays by combining them and removing duplicates
            const mergedArray = [...target[key]];

            for (const item of source[key]) {
              if (!mergedArray.some((existingItem) => existingItem.id === item.id)) {
                mergedArray.push(item);
              }
            }

            merged[key] = mergedArray;
          } else if (typeof source[key] === 'object' && !Array.isArray(source[key]) && (typeof target[key] === 'object')) {
            // If source[key] is an object and target[key] is also an object, recursively merge them
            merged[key] = this.mergeArraysRecursive(target[key], source[key]);
          } else if (target[key] === undefined) {
            // Assign the value from source to target if it's undefined in target
            merged[key] = source[key];
          }
        }
      }
      return merged;
    },
  },
  created() {
    if(this.categoryId === null) {
      this.$store.dispatch("shop/getPromotions", {limit: this.limit, start: this.start}).then((response) => {
        this.start = response.next_start;
        // console.log("Reduceri component response", response);
        const categories = response.products.map((item) => {
          let category = {};
          category.title = item.category.name;
          category.id = item.category.id;
          category.products = item.products;
          return category;
        });
        const responseArr = response.products.reduce((acc, item) => {
          const promoCategoryId = item.category.id;
          if (!acc[promoCategoryId]) {
            acc[promoCategoryId] = {
              categories: item.categories,
              products: item.products,
            };
          } else {
            acc[promoCategoryId].products.push(item.products);
          }
  
          if (!this.activeCategs.some((existingCategory) => existingCategory.id ===  item.category.id) && item.products) {
            this.activeCategs.push({id: item.category.id, title: item.category.name});
          }
  
          return acc;
        }, {});
        var resArr = this.mergeArraysRecursive(this.products, responseArr);
        this.products = resArr;
        this.filterCategs = categories;
        this.productsLoaded = true;
      });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("seo/setParams", {
      seo_title: "Promotii seminee - Focare semineu, sobe si accesorii",
      seo_description:
        "Promotii la seminee moderne sau rustice, focare, sobe pe lemne si accessori de cea mai buna calitate. Fiti la curent cu ultimele oferte din magazinul nostru!",
      seo_keywords: "seminee, promotii, accesorii seminee, focare, pefoc, sobe",
      seo_image: require("@/assets/images/reduceri-banner.webp"),
    });
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import "../../assets/css/promotii.css";
@import "../../assets/css/list_products.css";

.default-bg {
  /* background-color: #f5f4f2; */
}

.promotion-page {
  background-color: #f5f4f2;
  overflow-x: hidden;
}
.promotion-page .filter-items .filter-row {
  padding: 0 3px !important;
}
.promotion-page .filter-items .filter-name {
  height: 0;
}
</style>

<style>
#promotii .list-products-wrraper .container {
    position: relative;
}

#promotii .white-background .list-products-wrraper > div, #promotii .white-background ul {
    background: #fff;
    position: relative;
}

#promotii .white-background .list-products-wrraper > div:before, #promotii .white-background ul:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: -100%;
}

#promotii .white-background .list-products-wrraper > div:after, #promotii .white-background ul:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    right: -100%;
}

#promotii .catalog__list {
    margin-bottom: 0;
}

#promotii .list-categ-wrraper {
    background: #f5f4f2;
    position: relative;
    padding-top: 30px;
    padding-bottom: 60px;
}
 
#promotii .filter-mobile-sticky {
  top: 110px;
  right: 15px;
  position: fixed;
  z-index: 2;
}

#promotii .no-of-filters {
  color: #000000;
  background: #ffffff;
  border-radius: 50%;
  padding: 0px 3px 0 5px;
  margin-right: 7px;
}

#promotii .filter-button {
  background-color: #97040c;
  color: #ffffff;
  font-family: "Montserrat SemiBold";
  font-size: 12px;
  float: right;
}

#promotii .read-more {
  background-color: transparent;
}

#promotii .categ_short_desc {
  margin-bottom: 50px;
}

@media (max-width: 768px) {
    #promotii .list-categ-wrraper:before,
    #promotii .list-categ-wrraper:after,
    #promotii .list-products-wrraper .white-background:after,
    #promotii .list-products-wrraper .white-background:before {
        content: none;
    }
}

@media (max-width: 576px) {
    #promotii .catalog__item:nth-child(odd) {
        padding-right: 7.5px;
    }

    #promotii .catalog__item:nth-child(even) {
        padding-left: 7.5px;
    }

    #promotii .list-products-wrraper {
        background: #f5f4f2;
    }

    #promotii .categ_short_desc {
        background-color: #fff;
        min-height: 220px;
    }
    #promotii .categ_short_desc .categ-title {
        background-color: #fff;
        font-family: Raleway SemiBold;
        padding-top: 15px;
        margin-top: 0;
    }
    #promotii .categ_short_desc p{
        font-family: Montserrat Medium;
        font-size: 12px;
    }

    #promotii .list-categ-wrraper {
        padding-bottom: 0;
    }
    #promotii .list-categ-wrraper ul.row {
        padding: 0;
    }
    #promotii .list-categ-wrraper .lichidare-de-stoc {
        width: 70px;
    }
    #promotii .wpr-pr-general .lichidare-de-stoc img {
        width: 20px;
    }
    #promotii .promotions-title {
        background-color: #ffffff;
        border-bottom: solid 1px #979797;
        height: 43px;
        line-height: 43px;
        font-family: Montserrat Medium;
        color: #6c6c6c;
        font-size: 11px;
    }
    #promotii .read-more a {
      background-color: #fff;
    }
    #promotii .filter-mobile-sticky {
      top: 70px;
    }
}
</style>
