<template>
    <div class="observer"></div>
</template>

<script>
export default {
    name: 'observer',
    data() {
        return {
            observer: null
        };
    },
    mounted() {
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                if (entry.boundingClientRect.bottom > 0) {
                    this.$emit('intersect');
                }
            }
        }, {
            root: null, // Use the viewport as the root
            rootMargin: '0px 0px 200px 0px', // Adjust the rootMargin to your needs
        });

        this.observer.observe(this.$el);
    },
}
</script>

<style scoped>
    .observer {
        height: 1px;
    }
</style>