<template>
  <div class="section sticky" id="filter-mobile">
    <div class="filters filter-content filter-header">
      <a class="close" @click="closeFilter"></a>
      <div class="filter-items filter-default" id="filter">
        <div class="d-flex item">
          <div class="filter-name">
            <span class="filter-label">Filtreaza:</span>
          </div>

          <div class="button-group js-radio-button-group" data-filter-group="categ">
            <div v-for="categ in categories" :key="categ.id" class="filter-row">
              <button
                class="button"
                :data-filter="categ.id"
                @click="getPromo(categ.id)"
              >
                <a :class="'categ' + categ.id">{{ categ.title }} </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralFilter",
  props: ["categories"],
  emits: ["closeMobileFilter", "categoryId"],

  methods: {
    getPromo(category_id) {
      this.$emit('categoryId', category_id);
      setTimeout(() => {
        document.getElementById("promotii").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);

      const sections = document.querySelectorAll(".filter-items button a");
      // console.log(sections);
      sections.forEach((section) => {
        if(section.classList.contains("categ" + category_id)) {
          section.classList.add("active");
        } else {
          section.classList.remove("active");
        }
      });
      this.closeFilter();
    },

    closeFilter() {
      this.$emit("closeMobileFilter");
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
#filter .item a:hover,
#filter a.mPS2id-highlight,
#filter a.active {
  background: #97040c;
  border-radius: 28px;
}

.item {
  flex-wrap: wrap;
}

.filter-header .close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  z-index: 1;
}

.filter-header .close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 3px;
  background-color: #fff;
}
.filter-header .close:before {
  transform: rotate(45deg);
}
.filter-header .close:after {
  transform: rotate(-45deg);
}

.filter-items.filter-default .item {
  padding: 15px;
}

.filter-items.filter-default .button-group {
  padding: 0;
}

.sticky#filter-mobile {
  position: fixed;
  right: 0px;
  z-index: 99;
  margin: 0;
  top: 100px;
  width: 100%;
}

@media (max-width: 576px) {
  .sticky#filter-mobile {
    top: 60px;
  }
}
</style>
