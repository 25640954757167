<template>
            <!-- listare promotii -->
            <div class="content-list grile_view grile-inside grile-mobile">
                <!-- if are promotii -->
                <div class="container" v-if="windowWidth < 768">
                    <hr>
                </div>
                <div class="list-products-wrraper">
                    <!-- iterare categorii -->
                    <div
                        class="categ"
                    >
                        <div class="container" v-if="mergedProducts[category.id]">
                            <h2 class="categ-title promotii-categ-title">
                                {{ category.title }}
                            </h2>
                            <div id="" class="">
                                <!-- iterare produse -->
                                <ul class="catalog__list list-unstyled flex-wrap row">
                                    <CategoryPromotionCard
                                        v-for="(categ, index) in mergedProducts[category.id].categories"
                                        :key="index + categ.id"
                                        :categ="categ"
                                    />
                                    <li
                                        v-for="(
                                            product
                                        ) in mergedProducts[category.id].products"
                                        :key="product.id"
                                        class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6"
                                    >
                                        <product-card
                                            :product="product"
                                            @click="clickPromo(product)"
                                        />
                                    </li>
                                </ul>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <observer v-on:intersect="intersected" />
                <!--  endif  -->
            </div>
   
</template>
 
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import CategoryPromotionCard from "@/components/reduceri/CategoryPromotionCard.vue";
// import SkeletonProductCard from "@/components/blocks/skeleton/shop/SkeletonProductCard.vue";
import Observer from "@/components/blocks/Observer.vue";

export default {
    name: "Promotii",
    components: {
        ProductCard,
        CategoryPromotionCard,
        // SkeletonProductCard,
        Observer,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            // filterCategs: [],
            navbarFilterMobile: false,
            scrollNavbar: false,
            productsLoaded: false,
        }
    },
    props: {
        category: {
            type: Object,
            default: () => {}
        },
        products: {
            type: Object,
            default: () => {}
        },
        lastPage : {
            type: Boolean,
            default: () => false
        },
    },
    computed: {
        isLoad() {
            return this.$store.getters["shop/categoryIsLoad"];
        },
        mergedProducts() {
            return this.products;
        }
    },
    methods: {
        isEmptyObj(obj) {
            return (
                obj &&
                Object.keys(obj).length === 0 &&
                Object.getPrototypeOf(obj) === Object.prototype
            );
        },
        clickPromo($product) {
            this.$gtm.trackEvent({
                event: "promoProductClick",
                category: "Click",
                label: $product.name,
                value: $product.name,
            });
        },
        showMobileFilter() {
            this.navbarFilterMobile = true;
        },
        closeFilter() {
            this.navbarFilterMobile = false;
            this.$store.dispatch("filter/actionImgFilter", true);
        },
        intersected() {
            this.$emit('intersect');
        }
    },
};
</script>