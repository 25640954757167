<template>
    <div class="section filtersNavbar d-sm-block" ref="section">
      <div class="container container-mobile">
        <div class="filters filter-content filter-header">
          <div class="filter-items filter-default filters-mobile" id="filter">
            <div>
              <div class="row item">
                <div class="filter-name">
                  <span class="filter-label d-block d-sm-none">Filtreaza:</span>
                </div>
  
                <div style="padding: 0" class="button-group js-radio-button-group" data-filter-group="categ">
                  <div v-for="categ in categories" :key="categ.id" class="filter-row">
                    <button
                      class="button"
                      :data-filter="categ.id"
                      @click="getPromo(categ.id)"
                    >
                      <a :class="'categ' + categ.id">{{ categ.title }} </a>
                    </button>
                  </div>
                </div>
              </div>
  
              <div class="products-count d-block d-sm-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "PromotionsNavbarFilter",
    props: ["categories"],
    emits: ["categoryId"],
    methods: {
      getPromo(category_id) {
        this.$emit('categoryId', category_id);
        setTimeout(() => {
            document.getElementById("promotii").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        }, 300);
  
        const sections = document.querySelectorAll(".filter-items button a");
        // console.log(sections);
        sections.forEach((section) => {
          if(section.classList.contains("categ" + category_id)) {
            section.classList.add("active");
          } else {
            section.classList.remove("active");
          }
        });
      },
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  };
  </script>
  
  <style scoped>
  #filter .item a:hover,
  #filter a.mPS2id-highlight,
  #filter a.active {
    background: #97040c;
    border-radius: 28px;
  }
  
  .sticky.filtersNavbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 1);
    z-index: 16;
  }
  
  @media (max-width: 768px) {
    .filters-mobile {
      position: absolute;
      z-index: 109;
      height: auto;
      background-color: #000;
    }
  }
  </style>
  